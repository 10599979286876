/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:30a1ffc5-a276-479d-96d9-627a378085a5",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_DLbUkVXRG",
    "aws_user_pools_web_client_id": "7jeqj1a59a5l1ngchuepk6o773",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://5yc36dz44nfdxfzqdtuhknd54m.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "predictions": {
        "convert": {
            "transcription": {
                "region": "us-west-2",
                "proxy": false,
                "defaults": {
                    "language": "en-US"
                }
            },
            "speechGenerator": {
                "region": "us-west-2",
                "proxy": false,
                "defaults": {
                    "VoiceId": "Kendra",
                    "LanguageCode": "en-US"
                }
            }
        }
    }
};


export default awsmobile;
